<template>
  <data-table v-if="organisations.length > 0" :data="organisations" @row-click="organisationClick">
    <template #toolbar>
      <table-add-button @add="showAddModal = true" />
      <table-filters-button />
      <search-field />
    </template>

    <template #header>
      <table-header-cell field="name" label="Naam">Naam</table-header-cell>
      <table-header-cell field="auth0_org_id" label="Organisatie ID">Organisatie ID</table-header-cell>
      <table-header-cell field="user_count" label="Gebruikers">Gebruikers</table-header-cell>
      <table-header-cell field="proces_tool" label="Proces Tool">Procestool</table-header-cell>
      <table-header-cell field="actions" label="Acties" :filterable="false" :sortable="false">
        <div class="flex justify-center w-full">Acties</div>
      </table-header-cell>
    </template>

    <template #row="{ record }">
      <table-row-cell field="name">{{ record.name }}</table-row-cell>
      <table-row-cell field="auth0_org_id">{{ record.auth0_org_id }}</table-row-cell>
      <table-row-cell field="user_count">{{ record.user_count }}</table-row-cell>
      <table-row-cell field="proces_tool">{{ record.proces_tool }}</table-row-cell>
      <table-row-cell field="actions" class="flex flex-row justify-center">
        <table-delete-button @delete="deleteOrganisation(record.id)" />
      </table-row-cell>
    </template>
  </data-table>
  <baseModal v-if="showAddModal" size="m" @close="closeModal" title="Add organisation">
    <form class="w-full flex flex-col">
      <baseInput v-model="name" label="Name" type="string" id="name" />
      <baseCheckBox v-model="procesTool" label="Procestool" id="procesTool" />
    </form>
    <template v-slot:buttons>
      <baseButton @action="addOrganisation" class="mr-2" :loading="loadHandler.states['add_organisation']">
        {{ $translate('Add') }}
      </baseButton>
    </template>
  </baseModal>

  <baseModal v-if="showUsersModal" size="l" @close="closeUserModal" title="Users">
    <data-table :data="users">
      <template #toolbar>
        <table-add-button @add="showAddUser" />
        <table-filters-button />
        <search-field />
      </template>

      <template #header>
        <table-header-cell field="name" label="Naam">Naam</table-header-cell>
        <table-header-cell field="id" label="ID">ID</table-header-cell>
        <table-header-cell field="actions" label="Acties" :filterable="false" :sortable="false">
          <div class="flex justify-center w-full">Acties</div>
        </table-header-cell>
      </template>

      <template #row="{ record }">
        <table-row-cell field="name">{{ record.name }}</table-row-cell>
        <table-row-cell field="id">{{ record.id }}</table-row-cell>
        <table-row-cell field="actions" class="flex flex-row justify-center">
          <table-delete-button @delete="removeUser(record.id)" />
        </table-row-cell>
      </template>
    </data-table>
  </baseModal>

  <baseModal v-if="showAddUserModal" size="m" @close="closeAddUserModal" title="Add user">
    <form class="w-full flex flex-col">
      <data-table v-if="possibleUsers.length > 0" :data="possibleUsers" :selectable="true" ref="addUsersTable">
        <template #toolbar>
          <table-selection-button />
          <table-filters-button />
          <search-field />
        </template>

        <template #header>
          <table-select-all-checkbox />
          <table-header-cell field="name" label="Naam">Naam</table-header-cell>
          <table-header-cell field="id" label="ID">ID</table-header-cell>
        </template>

        <template #row="{ record }">
          <table-select-checkbox :record="record" />
          <table-row-cell field="name">{{ record.name }}</table-row-cell>
          <table-row-cell field="id">{{ record.id }}</table-row-cell>
        </template>
      </data-table>
    </form>
    <template v-slot:buttons>
      <baseButton @action="addUser" class="mr-2" :loading="loadHandler.states['add_user']">
        {{ $translate('Add') }}
      </baseButton>
    </template>
  </baseModal>
  <confimationModal :handler="confirm" />
</template>

<script setup>
import { ref, onMounted } from 'vue'
import apiHandler from '@/use/apiHandler'
import confirmationHandler from '@/use/confirmationHandler'
import loadingHandler from '@/use/loadingHandler'
import confimationModal from '@/components/extended/confirmationModal.vue'

const useApi = apiHandler()
const confirm = confirmationHandler()
const loadHandler = loadingHandler()
const showAddModal = ref(false)
const showUsersModal = ref(false)
const showAddUserModal = ref(false)
const organisations = ref([])
const addUsersTable = ref(null)

const name = ref('')
const procesTool = ref(false)
const users = ref([])

const selectedOrganisation = ref(null)
const selectedUsers = ref([])
const allUsers = ref([])
const possibleUsers = ref([])

onMounted(async () => {
  try {
    await refreshOrganisations()
  } catch (error) {
    console.error('Error fetching organisations:', error)
  }
  getUsers()
})

async function deleteOrganisation(id) {
  const ok = await confirm.open({
    title: 'Organisatie verwijderen?',
    message:
      'Weet je zeker dat je deze organisatie wilt verwijderen? Dit kan niet ongedaan worden gemaakt. Templates zullen ook verwijderd worden.',
  })
  if (ok) {
    try {
      await useApi.request('delete', `admin/organizations/${id}`)
      await refreshOrganisations()
    } catch (error) {
      console.error('Error deleting organisation:', error)
    }
  }
}

async function addOrganisation() {
  loadHandler.setLoadingState('add_organisation', true)
  try {
    await useApi.request('post', 'admin/organizations/', {
      name: name.value,
      proces_tool: procesTool.value,
    })
    await refreshOrganisations()
    closeModal()
  } catch (error) {
    console.error('Error adding organisation:', error)
  } finally {
    loadHandler.setLoadingState('add_organisation', false)
  }
}

async function getUsers() {
  try {
    const { data } = await useApi.request('get', 'admin/users')
    allUsers.value = data
  } catch (error) {
    console.error('Error fetching users:', error)
  }
}

function closeModal() {
  name.value = ''
  procesTool.value = false
  showAddModal.value = false
}

function closeUserModal() {
  users.value = []
  showUsersModal.value = false
}

function organisationClick(organisation) {
  showUsersModal.value = true
  selectedOrganisation.value = organisation
  users.value = organisation.users
}

function closeAddUserModal() {
  selectedUsers.value = []
  showAddUserModal.value = false
}

function showAddUser() {
  showAddUserModal.value = true
  possibleUsers.value = allUsers.value.filter((user) => {
    return !selectedOrganisation.value.users.some((orgUser) => orgUser.id === user.id)
  })
}

async function refreshOrganisations() {
  const { data: orgData } = await useApi.request('get', 'admin/organizations')
  // Create a new array with the user count calculated
  const updatedOrgs = orgData.map((org) => ({
    ...org,
    user_count: org.users.length,
  }))
  organisations.value = updatedOrgs

  // Update users list for the modal if an organization is selected
  if (selectedOrganisation.value) {
    const updatedOrg = updatedOrgs.find((org) => org.id === selectedOrganisation.value.id)
    if (updatedOrg) {
      selectedOrganisation.value = updatedOrg
      users.value = updatedOrg.users
    }
  }
}

async function removeUser(userId) {
  try {
    const orgId = selectedOrganisation.value.id
    await useApi.request('delete', `admin/organizations/${orgId}/users?users=${userId}`)
    await refreshOrganisations()
  } catch (error) {
    console.error('Error removing user:', error)
  }
}

async function addUser() {
  loadHandler.setLoadingState('add_user', true)
  const orgId = selectedOrganisation.value.id
  const userIds = addUsersTable.value.tableState.selectedRecords.value.map((user) => user.id)

  try {
    await useApi.request('post', `admin/organizations/${orgId}/users`, { users: userIds })
    await refreshOrganisations()
  } finally {
    loadHandler.setLoadingState('add_user', false)
    closeAddUserModal()
  }
}
</script>
