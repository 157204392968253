import { ref, computed } from 'vue'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'
import deelvlakListDisplay from '@/components/extended/deelvlakListDisplay.vue'
import dataChangeHandler from '@/use/dataChangeHandler'
import afgifteListDisplay from '@/components/extended/afgifteListDisplay.vue'
import toastHandler from '@/use/toastHandler'

export default () => {
  // Import warmteafgifte from another klimaatzone
  function importWarmteafgifte(targetKlimaatzoneIndex, sourceKlimaatzone) {
    if (!sourceKlimaatzone.warmteafgifte || sourceKlimaatzone.warmteafgifte.length === 0) {
      useToast.addToast('Geen warmteafgifte gevonden in geselecteerde klimaatzone', 'danger')
      return
    }

    // Copy warmteafgifte array from source to target
    data.value[targetKlimaatzoneIndex].warmteafgifte = JSON.parse(JSON.stringify(sourceKlimaatzone.warmteafgifte))
    useToast.addToast('Warmteafgifte succesvol geïmporteerd', 'success')
  }

  // data
  let data = ref([])
  let klimaatZoneShowAddModal = ref(false)
  let klimaatZoneShowEditModal = ref(false)
  let showAddModal = ref(false)
  let showEditModal = ref(false)
  let addIndexKlimaatzone = ref(null)
  let editIndexKlimaatzone = ref(null)
  let editNameKlimaatzone = ref(null)
  let editIndexbouwdeel = ref(null)
  let errors = ref([])

  const fieldconfigAfgifte = [
    {
      label: 'Afgifte',
      key: 'afgifte',
      type: 'select',
      options: selectValues.afgifte,
      required: true,
    },
    {
      label: 'Afmeting',
      key: 'afmeting',
      type: 'area',
      dependent_show_field_key: 'afgifte',
      dependent_show_field_type: 'select',
      dependent_show_values: [
        'Radiatoren type 10',
        'Radiatoren type 11',
        'Radiatoren type 20',
        'Radiatoren type 21',
        'Radiatoren type 22',
        'Radiatoren type 33',
        'Vloerverwarming (200mm hoh)',
        'Vloerverwarming (150mm hoh)',
        'Vloerverwarming (100mm hoh)',
        'Ledenradiator 160mm breed',
        'Ledenradiator 210mm breed',
        'Ledenradiator 270mm breed',
        'Badkamerradiator',
      ],
      required: true,
      unit: 'm2',
    },
    {
      label: 'Volume',
      key: 'volume',
      type: 'number',
      dependent_show_field_key: 'afgifte',
      dependent_show_field_type: 'select',
      dependent_show_values: ['Lucht'],
      required: true,
      unit: 'm3/h',
    },
    {
      label: 'Vermogen',
      key: 'vermogen',
      type: 'number',
      dependent_show_field_key: 'afgifte',
      dependent_show_field_type: 'select',
      dependent_show_values: ['Lokaal / handmatig', 'Laagtemperatuur radiatoren'],
      required: true,
      unit: 'kW',
    },
    {
      label: 'Lengte',
      key: 'lengte',
      type: 'number',
      dependent_show_field_key: 'afgifte',
      dependent_show_field_type: 'select',
      dependent_show_values: ['Convectorput'],
      required: true,
      unit: 'm',
    },
  ]

  const klimaatzoneFields = ref([
    {
      label: 'Naam',
      key: 'naam',
      type: 'string',
      required: true,
    },
    {
      label: 'Gebruiksoppervlak',
      key: 'verbruiksoppervlak',
      type: 'area',
      required: true,
      unit: 'm2',
    },
    {
      label: 'Setpoint ruimtetemperatuur',
      key: 'stookgedrag_setpoint',
      type: 'select',
      options: selectValues.setpoints,
      required: true,
      unit: '°C',
    },
    {
      label: 'Setpoint vermogensberekening',
      key: 'setpoint_vermogensberekening',
      type: 'select',
      options: selectValues.setpoints,
      required: true,
      default: selectValues.setpoints[5].value,
      unit: '°C',
    },
    {
      label: 'Verbruik vermindering',
      key: 'verbruik_vermindering',
      type: 'number',
      default: '0',
      required: true,
      unit: 'kWh',
    },
    {
      label: 'Verbruik vermindering reden',
      key: 'verbruik_vermindering_reden',
      type: 'string',
    },
    {
      label: 'Warmteafgifte',
      key: 'warmteafgifte',
      type: 'list',
      displayTemplate: afgifteListDisplay,
      modalSize: 's',
      fieldConfig: fieldconfigAfgifte,
      clickToEdit: true,
    },
    {
      label: 'Afwijkende opwekker',
      key: 'afwijkende_opwekker',
      type: 'toggle',
    },
    {
      label: 'Afwijkende ventilatie',
      key: 'afwijkende_ventilatie',
      type: 'toggle',
    },
    {
      label: 'Warmteopwekker',
      key: 'warmteopwekker',
      type: 'select',
      options: selectValues.warmteopwekkerNieuw,
      required: true,
      dependent_show_field_key: 'afwijkende_opwekker',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
    },
    {
      label: 'Ventilatie',
      type: 'subtitle',
      dependent_show_field_key: 'afwijkende_ventilatie',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
    },
    {
      label: 'Ventilatie (natuurlijk)',
      key: 'ventilatie_natuurlijk',
      type: 'select',
      options: selectValues.ventilatie,
      required: true,
      dependent_show_field_key: 'afwijkende_ventilatie',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
    },
    {
      label: 'Ventilatie (mechanisch)',
      key: 'ventilatie_mechanisch',
      type: 'number',
      required: true,
      dependent_show_field_key: 'afwijkende_ventilatie',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
      unit: 'm³/h',
    },
    {
      label: 'Ventilatie (WTW)',
      key: 'ventilatie_wtw',
      type: 'number',
      required: true,
      dependent_show_field_key: 'afwijkende_ventilatie',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
      unit: 'm³/h',
    },
    {
      label: 'Luchtdichtheid',
      key: 'luchtdichtheid',
      type: 'number',
      placeholder: 'Forfaitair',
      dependent_show_field_key: 'afwijkende_ventilatie',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
      unit: 'dm³/s*m²',
    },
  ])

  const klimaatzoneFieldsBasic = ref([
    {
      label: 'Naam',
      key: 'naam',
      type: 'string',
      required: true,
    },
    {
      label: 'Gebruiksoppervlak',
      key: 'verbruiksoppervlak',
      type: 'area',
      required: true,
      unit: 'm²',
    },
    {
      label: 'Setpoint ruimtetemperatuur',
      key: 'stookgedrag_setpoint',
      type: 'select',
      options: selectValues.setpoints,
      required: true,
      edit: false,
      unit: '°C',
    },
    {
      label: 'Setpoint vermogensberekening',
      key: 'setpoint_vermogensberekening',
      type: 'select',
      options: selectValues.setpoints,
      required: true,
      default: selectValues.setpoints[5].value,
      edit: false,
      unit: '°C',
    },
    {
      label: 'Verbruik vermindering',
      key: 'verbruik_vermindering',
      type: 'number',
      default: '0',
      required: true,
      edit: false,
      unit: 'kWh',
    },
    {
      label: 'Verbruik vermindering reden',
      key: 'verbruik_vermindering_reden',
      type: 'string',
      edit: false,
    },
    {
      label: 'Warmteafgifte',
      key: 'warmteafgifte',
      type: 'list',
      displayTemplate: afgifteListDisplay,
      modalSize: 's',
      fieldConfig: fieldconfigAfgifte,
      edit: false,
    },
    {
      label: 'Afwijkende opwekker',
      key: 'afwijkende_opwekker',
      type: 'toggle',
      edit: false,
    },
    {
      label: 'Afwijkende ventilatie',
      key: 'afwijkende_ventilatie',
      type: 'toggle',
      edit: false,
    },
    {
      label: 'Warmteopwekker',
      key: 'warmteopwekker',
      type: 'select',
      options: selectValues.warmteopwekkerNieuw,
      required: true,
      dependent_show_field_key: 'afwijkende_opwekker',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
      edit: false,
    },
    {
      label: 'Ventilatie',
      type: 'subtitle',
      dependent_show_field_key: 'afwijkende_ventilatie',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
      edit: false,
    },
    {
      label: 'Ventilatie (natuurlijk)',
      key: 'ventilatie_natuurlijk',
      type: 'select',
      options: selectValues.ventilatie,
      required: true,
      dependent_show_field_key: 'afwijkende_ventilatie',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
      edit: false,
    },
    {
      label: 'Ventilatie (mechanisch)',
      key: 'ventilatie_mechanisch',
      type: 'number',
      required: true,
      dependent_show_field_key: 'afwijkende_ventilatie',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
      edit: false,
      unit: 'm³/h',
    },
    {
      label: 'Ventilatie (WTW)',
      key: 'ventilatie_wtw',
      type: 'number',
      required: true,
      dependent_show_field_key: 'afwijkende_ventilatie',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
      edit: false,
      unit: 'm³/h',
    },
    {
      label: 'Luchtdichtheid',
      key: 'luchtdichtheid',
      type: 'number',
      placeholder: 'Forfaitair',
      dependent_show_field_key: 'afwijkende_ventilatie',
      dependent_show_field_type: 'check',
      dependent_show_values: [true],
      edit: false,
      unit: 'dm³/s*m²',
    },
  ])

  const BouwdeelFields = ref([
    {
      label: 'Omschrijving',
      key: 'omschrijving',
      type: 'string',
    },
    {
      label: 'Type',
      key: 'type',
      type: 'select',
      options: selectValues.bouwdeel,
      required: true,
    },
    {
      label: 'Isolatieniveau Rc',
      key: 'isolatieniveau',
      type: 'insulation',
      required: true,
      unit: 'm²K/W',
    },
    {
      label: 'Orientatie',
      key: 'orientatie',
      type: 'select',
      options: selectValues.orientatie,
      required: true,
    },
    {
      label: 'Oppervlakte',
      key: 'oppervlakte',
      type: 'area',
      required: true,
      unit: 'm²',
    },
    {
      label: 'Deelvlak',
      key: 'deelvlak',
      type: 'list',
      displayTemplate: deelvlakListDisplay,
      modalSize: 'xl',
      fieldConfig: [
        {
          label: 'Soort',
          key: 'soort',
          type: 'select',
          options: selectValues.glas_soort,
          add: true,
          edit: true,
          required: true,
        },
        {
          label: 'Oppervlakte',
          key: 'oppervlakte',
          type: 'area',
          add: true,
          edit: true,
          required: true,
          unit: 'm²',
        },
        {
          label: 'Belemmering',
          key: 'belemmering',
          type: 'percentage',
          add: true,
          edit: true,
          unit: '%',
        },
      ],
      clickToEdit: true,
    },
  ])

  // handlers
  const addKlimaatZoneForm = formHandler()
  const editKlimaatZoneForm = formHandler()
  const addBouwdeelForm = formHandler()
  const editBouwdeelForm = formHandler()
  const dataMonitor = dataChangeHandler()
  const useToast = toastHandler()

  function load(payload) {
    Object.keys(payload).forEach((key) => {
      data.value[key] = payload[key]
    })
    dataMonitor.sync(data.value)
    update_errors()
  }

  function openAdd(klimaatzoneIndex) {
    addIndexKlimaatzone.value = klimaatzoneIndex
    addBouwdeelForm.create({
      type: 'add',
      fields: BouwdeelFields.value,
      data: {},
    })
    showAddModal.value = true
  }

  function openAddKlimaatZone() {
    addKlimaatZoneForm.create({
      type: 'add',
      fields: klimaatzoneFields.value,
      data: {},
    })
    klimaatZoneShowAddModal.value = true
  }

  function openEditKlimaatZone(editData, klimaatzoneIndex, basic) {
    editIndexKlimaatzone.value = klimaatzoneIndex
    editNameKlimaatzone.value = editData.naam

    let localFields = {}
    if (basic) {
      localFields = klimaatzoneFieldsBasic.value
    } else {
      localFields = klimaatzoneFields.value
    }

    editKlimaatZoneForm.create({
      type: 'edit',
      fields: localFields,
      data: editData,
    })
    klimaatZoneShowEditModal.value = true
  }

  function openEdit(editData, klimaatzoneIndex, bouwdeelIndex) {
    editIndexKlimaatzone.value = klimaatzoneIndex
    editIndexbouwdeel.value = bouwdeelIndex
    editBouwdeelForm.create({
      type: 'edit',
      fields: BouwdeelFields.value,
      data: editData,
    })
    showEditModal.value = true
  }

  function addBouwdeel() {
    const payload = JSON.parse(JSON.stringify(addBouwdeelForm.data.value))

    if ('bouwdelen' in data.value[addIndexKlimaatzone.value]) {
      data.value[addIndexKlimaatzone.value].bouwdelen.push(payload)
    } else {
      data.value[addIndexKlimaatzone.value]['bouwdelen'] = [payload]
    }
    showAddModal.value = false
    update_errors()
  }

  function doesNaamExist(array, naamToCheck, ignoreList) {
    return array.some(function (obj) {
      var naamLower = obj.naam.toLowerCase()
      if (ignoreList.includes(naamLower)) {
        return false
      }
      return naamLower === naamToCheck.toLowerCase()
    })
  }

  function addKlimaatZone() {
    const payload = JSON.parse(JSON.stringify(addKlimaatZoneForm.data.value))
    const naam = payload.naam
    if (doesNaamExist(data.value, naam, [])) {
      useToast.addToast(`Klimaatzone '${naam}' bestaat al`, 'danger')
    } else {
      data.value.push(payload)
      klimaatZoneShowAddModal.value = false
      update_errors()
    }
  }

  function editKlimaatZone() {
    const payload = JSON.parse(JSON.stringify(editKlimaatZoneForm.data.value))
    const naam = payload.naam

    if (doesNaamExist(data.value, naam, [editNameKlimaatzone.value.toLowerCase()])) {
      useToast.addToast(`Klimaatzone '${naam}' bestaat al`, 'danger')
    } else {
      Object.assign(data.value[editIndexKlimaatzone.value], payload)
      closeEditKlimaatzone()
    }
  }

  function editBouwdeel() {
    const payload = { ...editBouwdeelForm.data.value }
    data.value[editIndexKlimaatzone.value].bouwdelen[editIndexbouwdeel.value] = payload
    closeEdit()
  }

  function removeBouwdeel(klimaatzoneIndex, bouwdeelIndex) {
    data.value[klimaatzoneIndex].bouwdelen.splice(bouwdeelIndex, 1)
    update_errors()
  }

  function removeKlimaatzone(klimaatzoneIndex) {
    data.value.splice(klimaatzoneIndex, 1)
    update_errors()
  }

  function syncBouwdeel(payload) {
    data.value = payload
    update_errors()
  }

  function duplicateBouwdeel(klimaatzoneIndex, bouwdeelIndex) {
    const payload = JSON.parse(JSON.stringify(data.value[klimaatzoneIndex].bouwdelen[bouwdeelIndex]))
    data.value[klimaatzoneIndex].bouwdelen.splice(bouwdeelIndex, 0, payload)
    update_errors()
  }

  function duplicateKlimaatzone(klimaatzoneIndex) {
    const payload = JSON.parse(JSON.stringify(data.value[klimaatzoneIndex]))

    // Split the naam at the space before the parentheses.
    let baseNaam = payload.naam
    let suffix = null

    const suffixMatch = payload.naam.match(/ \((\d+)\)$/)
    if (suffixMatch) {
      baseNaam = payload.naam.substring(0, suffixMatch.index)
      suffix = parseInt(suffixMatch[1])
    }

    // If a suffix was found, increment it. Otherwise, start at 1.
    if (suffix !== null) {
      suffix++
    } else {
      suffix = 1
    }

    // Create a new naam with the incremented suffix.
    let newNaam = `${baseNaam} (${suffix})`

    // If the new naam already exists, keep incrementing the suffix until a unique naam is found.
    while (data.value.some((e) => e.naam === newNaam)) {
      suffix++
      newNaam = `${baseNaam} (${suffix})`
    }

    // Assign the unique naam to the payload.
    payload.naam = newNaam

    data.value.splice(klimaatzoneIndex + 1, 0, payload)
    update_errors()
  }

  function closeAdd() {
    showAddModal.value = false
  }

  function closeAddKlimaatzone() {
    klimaatZoneShowAddModal.value = false
  }

  function closeEdit() {
    showEditModal.value = false
  }

  function closeEditKlimaatzone() {
    klimaatZoneShowEditModal.value = false
  }

  const showRows = computed(() => {
    if (data.value.bouwdelen) {
      if (data.value.bouwdelen.length > 0) {
        return true
      }
      return false
    }
    return false
  })

  function sumDeelvlak(bouwdeel) {
    let totalArea = 0
    if (bouwdeel.deelvlak) {
      bouwdeel.deelvlak.forEach((element) => {
        totalArea = totalArea + Number(element.oppervlakte)
      })
    }
    return totalArea
  }

  function findMissingRequiredFields(klimaatzones, BouwdeelFields) {
    let missingFieldsList = []

    klimaatzones.forEach((klimaatzone) => {
      if (klimaatzone.bouwdelen) {
        klimaatzone.bouwdelen.forEach((bouwdeel) => {
          BouwdeelFields.forEach((field) => {
            if (field.required && (bouwdeel[field.key] === undefined || bouwdeel[field.key] === '')) {
              missingFieldsList.push({
                klimaatzoneNaam: klimaatzone.naam,
                bouwdeelOmschrijving: bouwdeel.omschrijving,
                missingFieldLabel: field.label,
                missingFieldKey: field.key,
              })
            }
          })
        })
      }
    })

    return missingFieldsList
  }

  function update_errors() {
    let error_list = []

    // Check if there are any klimaatzones
    if (data.value.length > 0) {
      // Find missing required fields in bouwdelen
      const missingFields = findMissingRequiredFields(data.value, BouwdeelFields.value)
      missingFields.forEach((missingField) => {
        error_list.push({
          klimaatzoneNaam: missingField.klimaatzoneNaam,
          bouwdeelOmschrijving: missingField.bouwdeelOmschrijving,
          message: `${missingField.klimaatzoneNaam}: ${missingField.bouwdeelOmschrijving}: ${missingField.missingFieldLabel} niet ingevuld`,
        })
      })
    } else {
      // If no klimaatzones are entered, add an error message for that
      error_list.push({
        message: 'Geen klimaatzones ingevoerd',
      })
    }

    // Set the error list to the reactive errors reference
    errors.value = error_list
  }

  return {
    load,
    showRows,
    openAdd,
    closeAdd,
    showAddModal,
    showEditModal,
    addBouwdeelForm,
    addBouwdeel,
    data,
    removeBouwdeel,
    syncBouwdeel,
    duplicateBouwdeel,
    editBouwdeelForm,
    openEdit,
    closeEdit,
    editBouwdeel,
    sumDeelvlak,
    errors,
    dataMonitor,
    klimaatzoneFields,
    klimaatZoneShowAddModal,
    klimaatZoneShowEditModal,
    addKlimaatZoneForm,
    editKlimaatZoneForm,
    closeAddKlimaatzone,
    closeEditKlimaatzone,
    addKlimaatZone,
    openAddKlimaatZone,
    openEditKlimaatZone,
    editKlimaatZone,
    duplicateKlimaatzone,
    removeKlimaatzone,
    importWarmteafgifte,
  }
}
