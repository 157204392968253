const members = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
]

const bouwlagen = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
]

const soort_woning = [
  { label: 'Vrijstaand', value: 'Vrijstaand' },
  { label: '2/1 kap/hoekwoning', value: '2/1 kap/hoekwoning' },
  { label: 'Tussenwoning', value: 'Tussenwoning' },
  { label: 'Appartement', value: 'Appartement' },
]

const monumentale_status = [
  { label: 'Geen', value: 'Geen' },
  { label: 'Rijksmonument', value: 'Rijksmonument' },
  { label: 'Gemeentelijk monument', value: 'Gemeentelijk monument' },
  { label: 'Provinciaal monument', value: 'Provinciaal monument' },
  { label: 'Beschermd stads- of dorpsgezicht', value: 'Beschermd stads- of dorpsgezicht' },
]

const omgeving = [
  { label: 'Vrije veld', value: 'Vrije veld' },
  { label: 'Buiten de bebouwde kom, beschut', value: 'Buiten de bebouwde kom, beschut' },
  { label: 'Dorp', value: 'Dorp' },
  { label: 'Stad', value: 'Stad' },
]

const soort_vloer = [
  { label: 'Hout', value: 'hout' },
  { label: 'Beton', value: 'beton' },
  { label: 'Broodje ligger', value: 'broodje ligger' },
  { label: 'Kanaalplaat', value: 'kanaalplaat' },
  { label: 'Holle baksteen', value: 'holle baksteen' },
]

const kruipruimte = [
  { label: 'Niet aanwezig', value: 'niet aanwezig' },
  { label: '35 cm', value: '35 cm' },
  { label: '45 cm', value: '45 cm' },
  { label: '55 cm', value: '55 cm' },
  { label: '65 cm', value: '65 cm' },
  { label: 'Meer dan 65', value: 'meer dan 65' },
]

const soort_dak = [
  { label: 'Schuin dak', value: 'Schuin dak' },
  { label: 'Plat dak', value: 'Plat dak' },
]

const soort_dakbedekking = [
  { label: 'Pannen', value: 'pannen' },
  { label: 'Epdm/bitumen', value: 'epdm/bitumen' },
  { label: 'Riet', value: 'riet' },
]

const soort_muren = [
  { label: 'Spouw', value: 'spouw' },
  { label: 'Enkelsteens', value: 'enkelsteens' },
  { label: 'Halfsteens', value: 'halfsteens' },
  { label: 'HSB', value: 'HSB' },
]

const bouwjaar = [
  { label: '< 1945', value: '< 1945' },
  { label: '1945 - 1970', value: '1945 - 1970' },
  { label: '1970 - 1980', value: '1970 - 1980' },
  { label: '1980 - 1990', value: '1980 - 1990' },
  { label: '1990 - 2000', value: '1990 - 2000' },
  { label: '2000 - 2010', value: '2000 - 2010' },
  { label: '> 2010', value: '> 2010' },
]

const bouwjaar_standaardisolatie = [
  { label: '< 1920', value: '<1920' },
  { label: '1920 - 1965', value: '1920-1965' },
  { label: '1965 - 1975', value: '1965-1975' },
  { label: '1975 - 1988', value: '1975-1988' },
  { label: '1988 - 1992', value: '1988-1992' },
  { label: '1992 - 2012', value: '1992-2012' },
  { label: '2012 - 2015', value: '2012-2015' },
  { label: '2015 - 2021', value: '2015-2021' },
  { label: '> 2021', value: '>2021' },
]

const warmteopwekker = [
  { label: 'Warmtepomp + grondbuizen 35 graden', value: 'Warmtepomp + grondbuizen 35 graden' },
  { label: 'Warmtepomp + buitenlucht 35 graden', value: 'Warmtepomp + buitenlucht 35 graden' },
  { label: 'Warmtepomp hybride 35 graden', value: 'Warmtepomp hybride 35 graden' },
  { label: 'Warmtepomp + grondbuizen 45 graden', value: 'Warmtepomp + grondbuizen 45 graden' },
  { label: 'Warmtepomp + buitenlucht  45 graden', value: 'Warmtepomp + buitenlucht  45 graden' },
  { label: 'Warmtepomp hybride 45 graden', value: 'Warmtepomp hybride 45 graden' },
  { label: 'Warmtepomp + grondbuizen 55 graden', value: 'Warmtepomp + grondbuizen 55 graden' },
  { label: 'Warmtepomp + buitenlucht 55 graden', value: 'Warmtepomp + buitenlucht 55 graden' },
  { label: 'Warmtepomp hybride 55 graden', value: 'Warmtepomp hybride 55 graden' },
  { label: 'Warmtepomp + PVT systeem 35 graden', value: 'Warmtepomp + PVT systeem 35 graden' },
  { label: 'Warmtepomp + PVT systeem 45 graden', value: 'Warmtepomp + PVT systeem 45 graden' },
  { label: 'Warmtepomp + PVT systeem 55 graden', value: 'Warmtepomp + PVT systeem 55 graden' },
  { label: 'HR 107 gasketel LTV (recent)', value: 'HR 107 gasketel LTV (recent)' },
  { label: 'HR 107 gasketel HTV (recent)', value: 'HR 107 gasketel HTV (recent)' },
  { label: 'HR 107 gasketel HTV (oud)', value: 'HR 107 gasketel HTV (oud)' },
  { label: 'HR 100 gasketel HTV (oud)', value: 'HR 100 gasketel HTV (oud)' },
  { label: 'VR gasketel HTV (oud)', value: 'VR gasketel HTV (oud)' },
  { label: 'Warmtenet 45°C', value: 'Warmtenet 45°C' },
  { label: 'Warmtenet 55°C', value: 'Warmtenet 55°C' },
  { label: 'Warmtenet 65°C', value: 'Warmtenet 65°C' },
  { label: 'Gaskachels lokaal', value: 'Gaskachels lokaal' },
  { label: 'Infraroodpanelen / elektrische radiatoren', value: 'Infraroodpanelen / elektrische radiatoren' },
  { label: 'Gasboiler', value: 'Gasboiler' },
  { label: 'Geiser', value: 'Geiser' },
  { label: 'Elektrische boiler', value: 'Elektrische boiler' },
  { label: 'Elektrische doorstroomverwarmer', value: 'Elektrische doorstroomverwarmer' },
]

const warmteopwekkerNieuw = [
  { label: 'Warmtepomp + grondbuizen 35 graden', value: 'Warmtepomp + grondbuizen 35 graden' },
  { label: 'Warmtepomp + buitenlucht 35 graden', value: 'Warmtepomp + buitenlucht 35 graden' },
  { label: 'Warmtepomp + oppervlaktewater 35 graden', value: 'Warmtepomp + oppervlaktewater 35 graden' },
  { label: 'Warmtepomp hybride 35 graden', value: 'Warmtepomp hybride 35 graden' },
  { label: 'Warmtepomp + grondbuizen 45 graden', value: 'Warmtepomp + grondbuizen 45 graden' },
  { label: 'Warmtepomp + buitenlucht  45 graden', value: 'Warmtepomp + buitenlucht  45 graden' },
  { label: 'Warmtepomp + oppervlaktewater 45 graden', value: 'Warmtepomp + oppervlaktewater 45 graden' },
  { label: 'Warmtepomp hybride 45 graden', value: 'Warmtepomp hybride 45 graden' },
  { label: 'Warmtepomp + grondbuizen 55 graden', value: 'Warmtepomp + grondbuizen 55 graden' },
  { label: 'Warmtepomp + buitenlucht 55 graden', value: 'Warmtepomp + buitenlucht 55 graden' },
  { label: 'Warmtepomp + oppervlaktewater 55 graden', value: 'Warmtepomp + oppervlaktewater 55 graden' },
  { label: 'Warmtepomp hybride 55 graden', value: 'Warmtepomp hybride 55 graden' },
  { label: 'Warmtepomp + PVT systeem 35 graden', value: 'Warmtepomp + PVT systeem 35 graden' },
  { label: 'Warmtepomp + PVT systeem 45 graden', value: 'Warmtepomp + PVT systeem 45 graden' },
  { label: 'Warmtepomp + PVT systeem 55 graden', value: 'Warmtepomp + PVT systeem 55 graden' },
  { label: 'HR 107 gasketel LTV (recent)', value: 'HR 107 gasketel LTV (recent)' },
  { label: 'HR 107 gasketel HTV (recent)', value: 'HR 107 gasketel HTV (recent)' },
  { label: 'HR 100 gasketel HTV (oud)', value: 'HR 100 gasketel HTV (oud)' },
  { label: 'VR gasketel HTV (oud)', value: 'VR gasketel HTV (oud)' },
  { label: 'Warmtenet 45°C', value: 'Warmtenet 45°C' },
  { label: 'Warmtenet 55°C', value: 'Warmtenet 55°C' },
  { label: 'Warmtenet 65°C', value: 'Warmtenet 65°C' },
  { label: 'Gaskachels lokaal', value: 'Gaskachels lokaal' },
  { label: 'Infraroodpanelen / elektrische radiatoren', value: 'Infraroodpanelen / elektrische radiatoren' },
]

const bronTapwaterNieuw = [
  { label: 'Warmtepomp op buitenlucht; 120l boiler', value: 'Warmtepomp op buitenlucht; 120l boiler' },
  { label: 'Warmtepomp op buitenlucht; 200l boiler', value: 'Warmtepomp op buitenlucht; 200l boiler' },
  { label: 'Warmtepomp op buitenlucht; 300l boiler', value: 'Warmtepomp op buitenlucht; 300l boiler' },
  { label: 'Warmtepomp op buitenlucht; 400l boiler', value: 'Warmtepomp op buitenlucht; 400l boiler' },
  { label: 'Warmtepomp op grondbuizen; 150l boiler', value: 'Warmtepomp op grondbuizen; 150l boiler' },
  { label: 'Warmtepomp op grondbuizen; 200l boiler', value: 'Warmtepomp op grondbuizen; 200l boiler' },
  { label: 'Warmtepomp op grondbuizen; 300l boiler', value: 'Warmtepomp op grondbuizen; 300l boiler' },
  { label: 'Warmtepomp op grondbuizen; 400l boiler', value: 'Warmtepomp op grondbuizen; 400l boiler' },
  { label: 'Warmtepomp + PVT systeem; 150l boiler', value: 'Warmtepomp + PVT systeem; 150l boiler' },
  { label: 'Warmtepomp + PVT systeem; 200l boiler', value: 'Warmtepomp + PVT systeem; 200l boiler' },
  { label: 'Warmtepomp + PVT systeem; 300l boiler', value: 'Warmtepomp + PVT systeem; 300l boiler' },
  { label: 'Warmtepomp + PVT systeem; 400l boiler', value: 'Warmtepomp + PVT systeem; 400l boiler' },
  { label: 'Warmtepompboiler op buitenlucht; 150l', value: 'Warmtepompboiler op buitenlucht; 150l' },
  { label: 'Warmtepompboiler op buitenlucht; 200l', value: 'Warmtepompboiler op buitenlucht; 200l' },
  { label: 'Warmtepompboiler op buitenlucht; 300l', value: 'Warmtepompboiler op buitenlucht; 300l' },
  { label: 'Warmtepompboiler op buitenlucht; 400l', value: 'Warmtepompboiler op buitenlucht; 400l' },
  { label: 'Warmtepompboiler op ventilatielucht; 150l', value: 'Warmtepompboiler op ventilatielucht; 150l' },
  { label: 'Warmtepompboiler op ventilatielucht; 200l', value: 'Warmtepompboiler op ventilatielucht; 200l' },
  { label: 'Warmtepompboiler op ventilatielucht; 300l', value: 'Warmtepompboiler op ventilatielucht; 300l' },
  { label: 'Warmtepompboiler op ventilatielucht; 400l', value: 'Warmtepompboiler op ventilatielucht; 400l' },
  { label: 'Boosterwarmtepomp', value: 'Boosterwarmtepomp' },
  { label: 'Warmtepomp hybride', value: 'Warmtepomp hybride' },
  { label: 'HR 107 gasketel (recent)', value: 'HR 107 gasketel (recent)' },
  { label: 'HR 107 gasketel (>15j)', value: 'HR 107 gasketel (>15j)' },
  { label: 'HR 100 gasketel', value: 'HR 100 gasketel' },
  { label: 'VR gasketel', value: 'VR gasketel' },
  { label: 'Warmtenet', value: 'Warmtenet' },
  { label: 'Gasboiler', value: 'Gasboiler' },
  { label: 'Geiser', value: 'Geiser' },
  { label: 'Elektrische boiler', value: 'Elektrische boiler' },
  { label: 'Elektrische doorstroomverwarmer 11kW', value: 'Elektrische doorstroomverwarmer 11kW' },
  { label: 'Elektrische doorstroomverwarmer 22kW', value: 'Elektrische doorstroomverwarmer 22kW' },
]

const afgifte = [
  { label: 'Radiatoren type 10', value: 'Radiatoren type 10' },
  { label: 'Radiatoren type 11', value: 'Radiatoren type 11' },
  { label: 'Radiatoren type 20', value: 'Radiatoren type 20' },
  { label: 'Radiatoren type 21', value: 'Radiatoren type 21' },
  { label: 'Radiatoren type 22', value: 'Radiatoren type 22' },
  { label: 'Radiatoren type 33', value: 'Radiatoren type 33' },
  { label: 'Laagtemperatuur radiatoren', value: 'Laagtemperatuur radiatoren' },
  { label: 'Vloerverwarming (200mm hoh)', value: 'Vloerverwarming (200mm hoh)' },
  { label: 'Vloerverwarming (150mm hoh)', value: 'Vloerverwarming (150mm hoh)' },
  { label: 'Vloerverwarming (100mm hoh)', value: 'Vloerverwarming (100mm hoh)' },
  { label: 'Ledenradiator 160mm breed', value: 'Ledenradiator 160mm breed' },
  { label: 'Ledenradiator 210mm breed', value: 'Ledenradiator 210mm breed' },
  { label: 'Ledenradiator 270mm breed', value: 'Ledenradiator 270mm breed' },
  { label: 'Badkamerradiator', value: 'Badkamerradiator' },
  { label: 'Convectorput', value: 'Convectorput' },
  { label: 'Lucht', value: 'Lucht' },
  { label: 'Lokaal / handmatig', value: 'Lokaal / handmatig' },
]

const aansluitwaarde = [
  { label: '1x20A', value: '1x20A' },
  { label: '1x25A', value: '1x25A' },
  { label: '1x35A', value: '1x35A' },
  { label: '1x40A', value: '1x40A' },
  { label: '1x80A', value: '1x80A' },
  { label: '3x25A', value: '3x25A' },
  { label: '3X35A', value: '3X35A' },
]

const distributiesysteem = [
  { label: 'Ja, ongeïsoleerde leidingen', value: 'Ja, ongeïsoleerde leidingen' },
  { label: 'Ja, geïsoleerde leidingen', value: 'Ja, geïsoleerd leidingen' },
  { label: 'Geen leidingen buiten de thermische schil', value: 'Geen leidingen buiten de thermische schil' },
]

const koken = [
  { label: 'Gas', value: 'Gas' },
  { label: 'Elektrisch', value: 'Elektrisch' },
]

const setpoints = [
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
  { label: '23', value: 23 },
  { label: '24', value: 24 },
  { label: '25', value: 25 },
]

const boolean = [
  { label: 'Ja', value: 'Ja' },
  { label: 'Nee', value: 'Nee' },
]

const verbruik_tapwater = [
  { label: 'Laag', value: 'Laag' },
  { label: 'Gemiddeld', value: 'Gemiddeld' },
  { label: 'Hoog', value: 'Hoog' },
]

const ventilatie = [
  { label: 'Geen (alleen mechanisch)', value: 'Geen (alleen mechanisch)' },
  { label: 'Te openen ramen', value: 'Te openen ramen' },
  { label: 'Ventilatieroosters', value: 'Ventilatieroosters' },
]

const ventilatie_basic = [
  { label: 'Natuurlijke ventilatie', value: 'Natuurlijke ventilatie' },
  { label: 'Mechanische afzuiging', value: 'Mechanische afzuiging' },
  { label: 'WTW', value: 'WTW' },
]

const opwekking = [
  // { value: 'PV-panelen', label: 'PV-panelen' },
  { value: 'Geen', label: 'Geen' },
  { value: 'Zonneboiler 120l vat', label: 'Zonneboiler 120l vat' },
  { value: 'Zonneboiler 200l vat', label: 'Zonneboiler 200l vat' },
  { value: 'Zonneboiler 300l vat', label: 'Zonneboiler 300l vat' },
  { value: 'Zonneboiler 400l vat', label: 'Zonneboiler 400l vat' },
]

const bouwdeel = [
  { label: 'Gevel', value: 'Gevel' },
  { label: 'Vloer', value: 'Vloer' },
  { label: 'Dak', value: 'Dak' },
]

const orientatie = [
  { value: 'noord', label: 'noord' },
  { value: 'noordoost', label: 'noordoost' },
  { value: 'oost', label: 'oost' },
  { value: 'zuidoost', label: 'zuidoost' },
  { value: 'zuid', label: 'zuid' },
  { value: 'zuidwest', label: 'zuidwest' },
  { value: 'west', label: 'west' },
  { value: 'noordwest', label: 'noordwest' },
  { value: 'boven', label: 'boven' },
  { value: 'onder', label: 'onder' },
  { value: 'grond', label: 'grond' },
  { value: 'kruipruimte', label: 'kruipruimte' },
  { value: 'aangrenzende onverwarmde ruimte', label: 'aangrenzende onverwarmde ruimte' },
]

const orientatieZonnepanelen = [
  { value: 'Oost', label: 'Oost' },
  { value: 'Zuidoost', label: 'Zuidoost' },
  { value: 'Zuid', label: 'Zuid' },
  { value: 'Zuidwest', label: 'Zuidwest' },
  { value: 'West', label: 'West' },
]

const glas_soort = [
  { value: 'Drievoudige beglazing', label: 'Drievoudige beglazing' },
  { value: 'Drievoudige beglazing zonwerend', label: 'Drievoudige beglazing zonwerend' },
  { value: 'HR++', label: 'HR++' },
  { value: 'HR++ zonwerend', label: 'HR++ zonwerend' },
  { value: 'HR+ glas', label: 'HR+ glas' },
  { value: 'HR glas', label: 'HR glas' },
  { value: 'Dubbel glas', label: 'Dubbel glas' },
  { value: 'Enkel glas', label: 'Enkel glas' },
  { value: 'Ongeisoleerde deur', label: 'Ongeisoleerde deur' },
  { value: 'Geisoleerde deur', label: 'Geisoleerde deur' },
  { value: 'Paneel geen isolatie', label: 'Paneel geen isolatie' },
  { value: 'Paneel isolatie', label: 'Paneel isolatie' },
]

const bouwlaag_type = [
  { value: 'Begane grond', label: 'Begane grond' },
  { value: 'Verdieping', label: 'Verdieping' },
]

const ambitienNiveau = [
  {
    value: 'De klant wil kosten besparen door te verduurzamen',
    label: 'De klant wil kosten besparen door te verduurzamen',
  },
  { value: 'De klant wil een duurzamere woning', label: 'De klant wil een duurzamere woning' },
  { value: 'De klant wil een gasloze woning', label: 'De klant wil een gasloze woning' },
  { value: 'De klant wil de energierekening verlagen', label: 'De klant wil de energierekening verlagen' },
  { value: 'De klant wil CO2 besparen', label: 'De klant wil CO2 besparen' },
  {
    value: 'De klant wil zijn/haar bijdrage leveren voor onze planeet',
    label: 'De klant wil zijn/haar bijdrage leveren voor onze planeet',
  },
  { value: 'De klant wil een energieneutrale woning', label: 'De klant wil een energieneutrale woning' },
  {
    value: 'De klant wil een aansluiting op een HTV warmtenet',
    label: 'De klant wil een aansluiting op een HTV warmtenet',
  },
  {
    value: 'De klant wil een aansluiting op een LTV warmtenet',
    label: 'De klant wil een aansluiting op een LTV warmtenet',
  },
  { value: 'De klant wil iets anders', label: 'De klant wil iets anders' },
]

const omvormers = [
  { value: 'Micro-omvormers', label: 'Micro-omvormers' },
  { value: 'String-omvormer', label: 'String-omvormer' },
  { value: 'Optimizers', label: 'Optimizers' },
]

const beschaduwing = [
  { value: 0, label: '0' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 50, label: '50' },
  { value: 60, label: '60' },
  { value: 70, label: '70' },
  { value: 80, label: '80' },
  { value: 90, label: '90' },
]

const categorie_maatregelen = [
  { value: '', label: '' },
  { value: 'Bouwkundig', label: 'Bouwkundig' },
  { value: 'Ventilatie', label: 'Ventilatie' },
  { value: 'Warmteopwek- & afgifte', label: 'Warmteopwek- & afgifte' },
  { value: 'Eigen productie', label: 'Eigen productie' },
]

const eenheid_maatregelen = [
  { value: 'm', label: 'm' },
  { value: 'm2', label: 'm2' },
  { value: 'm3', label: 'm3' },
  { value: 'stuk', label: 'stuk' },
]

const months = [
  { label: 'Januari', value: 0 },
  { label: 'Februari', value: 1 },
  { label: 'Maart', value: 2 },
  { label: 'April', value: 3 },
  { label: 'Mei', value: 4 },
  { label: 'Juni', value: 5 },
  { label: 'Juli', value: 6 },
  { label: 'Augustus', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
]

const stapTypen = [
  { label: 'Simpel', value: 'Simpel' },
  { label: 'Adres', value: 'Adres' },
]

// build years select
let jaar_verbouwing = []
const currentyear = new Date().getFullYear()
const years = Array.from({ length: 20 }, (x, i) => i + currentyear)

years.forEach((year) => {
  jaar_verbouwing.push({
    value: year,
    label: year.toString(),
  })
})

export default {
  members,
  bouwlagen,
  soort_woning,
  omgeving,
  soort_vloer,
  kruipruimte,
  soort_dak,
  soort_dakbedekking,
  soort_muren,
  bouwjaar,
  warmteopwekker,
  afgifte,
  aansluitwaarde,
  koken,
  setpoints,
  boolean,
  verbruik_tapwater,
  ventilatie,
  distributiesysteem,
  opwekking,
  bouwdeel,
  orientatie,
  glas_soort,
  bouwlaag_type,
  jaar_verbouwing,
  ambitienNiveau,
  warmteopwekkerNieuw,
  bronTapwaterNieuw,
  omvormers,
  beschaduwing,
  orientatieZonnepanelen,
  ventilatie_basic,
  bouwjaar_standaardisolatie,
  categorie_maatregelen,
  eenheid_maatregelen,
  months,
  stapTypen,
  monumentale_status,
}
